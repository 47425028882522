import React from 'react';
import {
  Route,
  Switch,
  withRouter,
  useHistory,
  Redirect,
} from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';

import * as ROUTES from '../../constants/routes';
import lazyComponent from '../../hoc/lazyComponent/lazyComponent';
import LandingPage from '../../pages/LandingPage/LandingPage';

import PrivateRoute from '../PrivateRoute/PrivateRoute';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import GiftCardPage from '../../pages/GiftCardPage/GiftCardPage';

// Import lazyloading components
const LazyProductPage = lazyComponent(() => {
  return import(/* webpackChunkName: "product" */ '../../pages/ProductPage/ProductPage');
});
LazyProductPage.preload();
const LazyWishlistPage = lazyComponent(() => {
  return import(/* webpackChunkName: "wishlist" */ '../../pages/WishlistPage/WishlistPage');
});
const LazyCartPage = lazyComponent(() => {
  return import(/* webpackChunkName: "cart" */ '../../pages/CartPage/CartPage');
});
const LazyNotFoundPage = lazyComponent(() => {
  return import(/* webpackChunkName: "404" */ '../../pages/NotFoundPage/NotFoundPage');
});
const LazyCategoryPage = lazyComponent(() => {
  return import(/* webpackChunkName: "404" */ '../../pages/CategoryPage/CategoryPage');
});
const LazyShowPage = lazyComponent(() => {
  return import(/* webpackChunkName: "show" */ '../../pages/ShowPage/ShowPage');
});
const LazyCheckoutPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/CheckoutPage/CheckoutPage');
});
const LazyPaymentPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/PaymentPage/PaymentPage');
});
const LazyGiftCardPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/GiftCardPage/GiftCardPage');
});
const LazyEventPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/EventPage/EventPage');
});
const LazyUpcomingShowPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/UpcomingShowPage/UpcomingShowPage');
});

const LazyEventListPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/EventList/EventList');
});
const LazyBlogAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/blog/BlogAutokinoPage/BlogAutokinoPage');
});
const LazyBlogFlohmarktPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/blog/BlogFlohmarktPage/BlogFlohmarktPage');
});
const LazyKinderflohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xkinderlohmarkt_wien_sonntag');
});
const LazyGeschichtePage = lazyComponent(() => {
  return import('../../pages/flohmarkt/GeschichtePage');
});
const LazyKundenliebePage = lazyComponent(() => {
  return import('../../pages/flohmarkt/KundenliebePage');
});
const LazyBesuchertippsPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xtipps_und_tricks_fuer_flohmarkt_besucher');
});
const LazyFaqPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xfaqs_flohmarkt_wien_sonntag');
});
const LazyMarktordnungPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xflohmarkt_regeln_marktordnung');
});
const LazyChecklistVerkaeuferPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xchecklist_fuer_flohmarkt_verkaufer');
});
const LazyTippsVerkaeuferPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xtipps_tricks_fuer_deinen_erfolgreichen_flohmarkt_verkauf');
});
const LazyAnreisePage = lazyComponent(() => {
  return import('../../pages/flohmarkt/AnreisePage');
});
const LazyAnreiseNewPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xanreise_gratis_parken');
});

const LazyFotosPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xflohmarkt_heute_fotos');
});
const LazyStandplatzgebuehrenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/xflohmarkt_standplaetze_standgebuehren_platzmiete');
});
const LazyBikeFaqPage = lazyComponent(() => {
  return import('../../pages/autokino/BikeFaqPage');
});
const LazyCustomerFeedbackFormPage = lazyComponent(() => {
  return import('../../pages/CustomerFeedbackFormPage/CustomerFeedbackFormPage');
});
const LazySuccessPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/SuccessPage/SuccessPage');
});
const LazyEventLocationPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/EventLocationPage');
});
const LazyAnreiseAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/AnreiseAutokinoPage');
});
const LazyFaqAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/FaqAutokinoPage');
});
const LazyHaustierePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/HaustierePage');
});
const LazyJobsPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/JobsPage');
});
const LazyPhilosophiePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/PhilosophiePage');
});
const LazyOpenAirKinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/OpenAirKinoPage');
});
const LazySiegerKinotestPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/SiegerKinotestPage');
});
const LazyAutokinoPartnerPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/AutokinoPartnerPage');
});
const LazyDasAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/DasAutokinoPage');
});
const LazyHomePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/HomePage/HomePage');
});
const LazyMoviePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/MoviePage/MoviePage');
});
const LazyFlohmarktMistelbachPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/landingpages/xflohmarkt_mistelbach');
});
const LazyFlohmarktJobsPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/flohmarkt/FlohmarktJobsPage');
});
const LazyFlohmarktNiederoesterreichPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/landingpages/xflohmarkt_niederoesterreich');
});
const LazyFlohmarktGaenserndorfPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/landingpages/xflohmarkt_gaenserndorf');
});
const LazyShowNotFoundPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/ShowNotFoundPage/ShowNotFoundPage');
});
const LazyOrderStornoPage = lazyComponent(() => {
  return import('../../pages/OrderStornoPage/OrderStornoPage');
});

const LazyLandingPageMinions4Page = lazyComponent(() => {
  return import('../../pages/autokino/LandingPageMinions4Page');
});
const LazyFMWinterpausePage = lazyComponent(() => {
  return import('../../pages/flohmarkt/FMWinterpausePage');
});
const LazyKinoWinterpausePage = lazyComponent(() => {
  return import('../../pages/autokino/KinoWinterpausePage');
});

const LazyGenericLandingPageMinions4Page = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/GenericTestPage');
});
const LazyJobApplicationSuccessPage = lazyComponent(() => {
  return import('../../pages/JobApplicationSuccessPage/JobApplicationSuccessPage');
});
const LazyAnfaengerFlohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/anfaenger_tipps_flohmarkt');
});
const LazyAnmeldenFlohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/anmelden_flohmarkt');
});
const LazyAusstellerWissenLexikonPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/aussteller_wissens_lexikon');
});
const LazyBabyflohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/Babyflohmarkt');
});
const LazyBestenFlohmaerkteWien = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/bester_flohmarkt_wien');
});
const LazyBesteFlohmaerkteUebersichtPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/bester_flohmarkt');
});
const LazyDiebstahlPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/diebstahl');
});
const LazyDurchschnittlicheEinnahmenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/durchschnittliche_flohmarkt_einnahmen');
});
const LazyErfolgreichHandelnPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/erfolgreich_handeln_am_flohmarkt');
});
const LazyFlohmarktInDerNaehePage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/flohmarkt_in_der_naehe');
});
const LazyLeihtischePage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/flohmarkt_leihtische');
});
const LazyFlohmarktListePage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/flohmarkt_liste');
});
const LazyFlohmarktOhneStandgebuehr = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/flohmarkt_ohne_standgebuehr');
});
const LazyFlohmarktOnlineVergleichenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/flohmarkt_online_vergleichen');
});
const LazyFlohmarktPlatzReservierenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/flohmarkt_platz_reservieren');
});
const LazyPreisgestaltungPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/flohmarkt_ware_preis_gestalten');
});
const LazyGeldverdienenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/geld_verdienen');
});
const LazyGenehmigungAnmeldungPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/genehmigung_anmeldung_flohmarkt');
});
const LazyGewerbeGewerbescheinPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/gewerbe_gewerbeschein');
});
const LazyGroessterFlohmarktWienPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/groesster_flohmarkt_wien');
});
const LazyHobbyFlohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/hobby_flohmarkt');
});
const LazyJetztStandplatzBuchenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/jetzt_flohmarktstand_buchen');
});
const LazyKinderVerkaeuferPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/kinder_als_verkauefer');
});
const LazyKinderflohmarktChecklistePage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/kinderflohmarkt_checkliste');
});
const LazyTippsPreisePage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/kinderflohmarkt_preise_ware');
});
const LazyFlohmarktPreisePage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/kleidung_verkaufen_flohmarkt');
});
const LazyFlohmarktMitmachenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/mitmachen_flohmarkt');
});
const LazyFlohmarktNachhaltigkeitPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/nachhaltigkeit_flohmarkt');
});
const LazyFlohmarktNeuwarePage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/neuware_verkaufen');
});
const LazyFlohmarktToolsPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/nuetzliche_flohmarkttools');
});
const LazyPacklisteFlohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/packliste_flohmarkt');
});
const LazyParkplatzFlohmarkt = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/parkplatz_flohmarkt');
});
const LazyPreisschilderFlohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/preisschilder_flohmarkt');
});
const LazyRegenFlohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/regen_flohmarkt');
});
const LazyWarenReinigenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/reinigen_aufbereiten_flohmarktware');
});
const LazyFlohmarktOrganisationPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/sortieren_organisieren');
});
const LazyStandGestaltenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/stand_gestalten_flohmarkt');
});
const LazyStromtesterPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/stromtester');
});
const LazyTippKinderflohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/tipps_verkauf_kinderflohmarkt');
});
const LazyUebrigeWarenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/uebergebliebene_ware');
});
const LazyUmgangKundenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/umgang_flohmarkt_kunden');
});
const LazyVorteileAusstellerPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/vorteile_aussteller_flohmarkt_autokino');
});
const LazyWareLagernPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/ware_lagern');
});
const LazyGuteWarenPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/was_verkauft_sich_gut_auf_flohmaerkten');
});
const LazyWechselgeldPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/wechselgeld_am_flohmarkt');
});
const LazyRichtigerFlohmarktPage = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/wie_finde_ich_den_richtigen_flohmarkt');
});

const RouteContainer = posed.div({
  enter: { opacity: 1, x: '0%' },
  exit: { opacity: 0, x: '-100%' },
});
const trackPageView = () => {
  console.log('PageView Event', window.location.pathname);
  ReactPixel.pageView(window.location.pathname);
};
const Routes = props => {
  const history = useHistory();
  ReactGA.send('pageview');
  if (window.location.pathname === '/cart') {
    ReactGA.event('begin_checkout', {});
  }
  React.useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
    if (window.location.pathname === '/cart') {
      ReactPixel.track('InitiateCheckout');
    }
  }, [history]);

  return (
    <PoseGroup>
      <RouteContainer key={props.location.key + 'asd'}>
        <Switch location={props.location}>
          <Route
            path={ROUTES.LANDING}
            exact
            component={LandingPage}
            key="landing"
          />

          <Route
            path={`${ROUTES.GENERIC_LANDING_PAGE}`}
            component={LazyGenericLandingPageMinions4Page}
            exact
            key="genericLandingPage"
          />

          <Route
            path={`${ROUTES.RICHTIGER_FLOHMARKT}`}
            component={LazyRichtigerFlohmarktPage}
            exact
            key="richtigerFlohmarkt"
          />
          <Route
            path={`${ROUTES.WECHSELGELD}`}
            component={LazyWechselgeldPage}
            exact
            key="wechselgeld"
          />
          <Route
            path={`${ROUTES.GUTE_WAREN}`}
            component={LazyGuteWarenPage}
            exact
            key="guteWaren"
          />
          <Route
            path={`${ROUTES.WARE_LAGERN}`}
            component={LazyWareLagernPage}
            exact
            key="wareLagern"
          />
          <Route
            path={`${ROUTES.VORTEILE_AUSSTELLER}`}
            component={LazyVorteileAusstellerPage}
            exact
            key="vorteileAussteller"
          />
          <Route
            path={`${ROUTES.UMGANG_KUNDEN}`}
            component={LazyUmgangKundenPage}
            exact
            key="umgangKunden"
          />
          <Route
            path={`${ROUTES.UEBRIG_GEBLIEBENE_WAREN}`}
            component={LazyUebrigeWarenPage}
            exact
            key="uebrigGebliebeneWaren"
          />
          <Route
            path={`${ROUTES.TIPPS_KINDERFLOHMARKT}`}
            component={LazyTippKinderflohmarktPage}
            exact
            key="tippsKinderflohmarkt"
          />
          <Route
            path={`${ROUTES.STROMTESTER}`}
            component={LazyStromtesterPage}
            exact
            key="stromtester"
          />
          <Route
            path={`${ROUTES.STAND_GESTALTEN}`}
            component={LazyStandGestaltenPage}
            exact
            key="standGestalten"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_ORGANISATION}`}
            component={LazyFlohmarktOrganisationPage}
            exact
            key="flohmarktOrganisation"
          />
          <Route
            path={`${ROUTES.WAREN_REINIGEN}`}
            component={LazyWarenReinigenPage}
            exact
            key="warenReinigen"
          />
          <Route
            path={`${ROUTES.REGEN_FLOHMARKT}`}
            component={LazyRegenFlohmarktPage}
            exact
            key="regenFlohmarkt"
          />
          <Route
            path={`${ROUTES.PREISSCHILDER_FLOHMARKT}`}
            component={LazyPreisschilderFlohmarktPage}
            exact
            key="preisschilderFlohmarkt"
          />
          <Route
            path={`${ROUTES.PARKPLATZ_FLOHMARKT}`}
            component={LazyParkplatzFlohmarkt}
            exact
            key="parkplatzFlohmarkt"
          />
          <Route
            path={`${ROUTES.PACKLISTE_FLOHMARKT}`}
            component={LazyPacklisteFlohmarktPage}
            exact
            key="flohmarktPackliste"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_TOOLS}`}
            component={LazyFlohmarktToolsPage}
            exact
            key="flohmarktTools"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_NEUWARE}`}
            component={LazyFlohmarktNeuwarePage}
            exact
            key="flohmarktNeuware"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_NACHHALTIGKEIT}`}
            component={LazyFlohmarktNachhaltigkeitPage}
            exact
            key="flohmarktNachhaltigkeit"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_MITMACHEN}`}
            component={LazyFlohmarktMitmachenPage}
            exact
            key="flohmarktMitmachen"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_PREISE}`}
            component={LazyFlohmarktPreisePage}
            exact
            key="flohmarktPreise"
          />
          <Route
            path={`${ROUTES.TIPPS_PREISE}`}
            component={LazyTippsPreisePage}
            exact
            key="tippsPreise"
          />
          <Route
            path={`${ROUTES.KINDER_VERKAEUFER}`}
            component={LazyKinderVerkaeuferPage}
            exact
            key="kinderVerkaeufer"
          />

          <Route
            path={`${ROUTES.KINDERFLOHMARKT_CHECKLISTE}`}
            component={LazyKinderflohmarktChecklistePage}
            exact
            key="kinderFlohmarktCheckliste"
          />
          <Route
            path={`${ROUTES.ANFAENGER_TIPPS_FLOHMARKT}`}
            component={LazyAnfaengerFlohmarktPage}
            exact
            key="anfaengerTippsFlohmarkt"
          />
          <Route
            path={`${ROUTES.ANMELDEN_FLOHMARKT}`}
            component={LazyAnmeldenFlohmarktPage}
            exact
            key="anmeldenFlohmarkt"
          />
          <Route
            path={`${ROUTES.AUSSTELLER_WISSEN_LEXIKON}`}
            component={LazyAusstellerWissenLexikonPage}
            exact
            key="ausstellerWissenLexikon"
          />
          <Route
            path={`${ROUTES.BABYFLOHMARKT}`}
            component={LazyBabyflohmarktPage}
            exact
            key="babyflohmarkt"
          />
          <Route
            path={`${ROUTES.BESTEN_FLOHMAERKTE_WIEN}`}
            component={LazyBestenFlohmaerkteWien}
            exact
            key="bestenFlohmaerkteWien"
          />
          <Route
            path={`${ROUTES.BESTE_FLOHMAERKTE_UEBERSICHT}`}
            component={LazyBesteFlohmaerkteUebersichtPage}
            exact
            key="besteFlohmaerkteUebersicht"
          />
          <Route
            path={`${ROUTES.DIEBSTAHL}`}
            component={LazyDiebstahlPage}
            exact
            key="diebstahl"
          />
          <Route
            path={`${ROUTES.DURCHSCHNITTLICHE_EINNAHMEN}`}
            component={LazyDurchschnittlicheEinnahmenPage}
            exact
            key="durchschnittlicheEinnahmen"
          />
          <Route
            path={`${ROUTES.ERFOLGREICH_HANDELN}`}
            component={LazyErfolgreichHandelnPage}
            exact
            key="erfolgreichHandeln"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_IN_DER_NAEHE}`}
            component={LazyFlohmarktInDerNaehePage}
            exact
            key="flohmarktInderNaehe"
          />
          <Route
            path={`${ROUTES.LEIHTISCHE}`}
            component={LazyLeihtischePage}
            exact
            key="Leihtische"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_LISTE}`}
            component={LazyFlohmarktListePage}
            exact
            key="FlohmarktListe"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_OHNE_STANDGEBUEHR}`}
            component={LazyFlohmarktOhneStandgebuehr}
            exact
            key="FlohmarktOhneStandgebuehr"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_ONLINE_VERGLEICHEN}`}
            component={LazyFlohmarktOnlineVergleichenPage}
            exact
            key="FlohmarktOnlineVergleichen"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_PLATZ_RESERVIEREN}`}
            component={LazyFlohmarktPlatzReservierenPage}
            exact
            key="FlohmarktOnlineVergleichen"
          />
          <Route
            path={`${ROUTES.PREISGESTALTUNG}`}
            component={LazyPreisgestaltungPage}
            exact
            key="Preisgestaltung"
          />
          <Route
            path={`${ROUTES.GELD_VERDIENEN}`}
            component={LazyGeldverdienenPage}
            exact
            key="Geld verdienen"
          />
          <Route
            path={`${ROUTES.GENEHMIGUNG_ANMELDUNG}`}
            component={LazyGenehmigungAnmeldungPage}
            exact
            key="GenehmigungAnmeldung"
          />
          <Route
            path={`${ROUTES.GEWERBE_GEWERBESCHEIN}`}
            component={LazyGewerbeGewerbescheinPage}
            exact
            key="GewerbeGewerbeschein"
          />
          <Route
            path={`${ROUTES.GROESSTER_FLOHMARKT_WIEN}`}
            component={LazyGroessterFlohmarktWienPage}
            exact
            key="GroessterFlohmarktWien"
          />
          <Route
            path={`${ROUTES.HOBBY_FLOHMARKT}`}
            component={LazyHobbyFlohmarktPage}
            exact
            key="HobbyFlohmarkt"
          />
          <Route
            path={`${ROUTES.JETZT_STANDPLATZ_BUCHEN}`}
            component={LazyJetztStandplatzBuchenPage}
            exact
            key="JetztStandplatzBuchen"
          />


          <Route
            path={`${ROUTES.GIFTCARD}`}
            component={LazyGiftCardPage}
            exact
            key="giftCart"
          />
          <Route
            path={`${ROUTES.EVENT}`}
            component={LazyEventListPage}
            exact
            key="flohmarkt"
          />
          <Route path={`${ROUTES.AUTOMARKT}`} exact>
            <Redirect to="/" />
          </Route>
          <Route
            path={`${ROUTES.BLOGAUTOKINO}`}
            component={LazyBlogAutokinoPage}
            exact
            key="Blog Autokino"
          />
          <Route
            path={`${ROUTES.BLOGFLOHMARKT}`}
            component={LazyBlogFlohmarktPage}
            exact
            key="Blog Flohmarkt"
          />
          <Route
            path={`${ROUTES.UPCOMING_MOVIES}`}
            component={LazyUpcomingShowPage}
            exact
            key="upcomingmovies"
          />
          <Route
            path={`${ROUTES.EVENT_ITEM}/:id`}
            component={LazyEventPage}
            key="event"
          />
          <Route
            path={`${ROUTES.SHOW}/:id`}
            component={LazyShowPage}
            key="show"
          />
          <Route
            path={`${ROUTES.MOVIE_SHOW}/:name`}
            component={LazyShowPage}
            key="show"
          />
          <Route
            path={`${ROUTES.EVENT_FLOHMARKT_DETAIL}/:name`}
            component={LazyEventPage}
            key="event"
          />
          <Route
            path={`${ROUTES.PUBLIC_EVENT_DETAIL}/:name`}
            component={LazyEventPage}
            key="event"
          />
          <Route
            path={`${ROUTES.MOVIE_DETAIL}/:name`}
            component={LazyMoviePage}
            key="movie"
          />
          <Route
            path={`${ROUTES.ORDER_STORNO}/:name`}
            component={LazyOrderStornoPage}
            key="orderStorno"
          />
          <Route
            path={ROUTES.CATEGORY}
            component={LazyCategoryPage}
            key="category"
          />
          <Route
            path={`${ROUTES.KINDERFLOHMARKT}`}
            component={LazyKinderflohmarktPage}
            exact
            key="Kinderflohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_GESCHICHTE}`}
            component={LazyGeschichtePage}
            exact
            key="Geschichte Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_KUNDENLIEBE}`}
            component={LazyKundenliebePage}
            exact
            key="Kundenliebe Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_BESUCHER_TIPPS}`}
            component={LazyBesuchertippsPage}
            exact
            key="Besuchertipps Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_FAQ}`}
            component={LazyFaqPage}
            exact
            key="Fragen und Antworten zum Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.AUTOKINO_BIKE_FAQ}`}
            component={LazyBikeFaqPage}
            exact
            key="Fragen und Antworten zu Kinotickets für Fahrrad und Motorrad im Autokino Wien"
          />
          <Route
            path={`${ROUTES.MINIONS4_AUTOKINO}`}
            component={LazyLandingPageMinions4Page}
            exact
            key="Die Minions 4 im Autokino Wien"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_WINTERPAUSE}`}
            component={LazyFMWinterpausePage}
            exact
            key="Flohmarkt Wien - Winterpause 2024"
          />
          <Route
            path={`${ROUTES.AUTOKINO_WINTERPAUSE}`}
            component={LazyKinoWinterpausePage}
            exact
            key="Autokino Wien - Winterpause 2024"
          />

          <Route
            path={`${ROUTES.FLOHMARKT_MARKTORDNUNG}`}
            component={LazyMarktordnungPage}
            exact
            key="Marktordnung Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_CHECKLIST}`}
            component={LazyChecklistVerkaeuferPage}
            exact
            key="Checklist Verkäufer Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_VERKAEUFER_TIPPS}`}
            component={LazyTippsVerkaeuferPage}
            exact
            key="Tipps Verkäufer Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_ANREISE}`}
            component={LazyAnreiseNewPage}
            exact
            key="Anreise Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_FOTOS}`}
            component={LazyFotosPage}
            exact
            key="Fotos Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_STANDGEBUEHREN}`}
            component={LazyStandplatzgebuehrenPage}
            exact
            key="Standplatzgebühren Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.CUSTOMER_FEEDBACK_FORM}`}
            component={LazyCustomerFeedbackFormPage}
            exact
            key="Deine Meinung zählt"
          />
          <Route
            path={`${ROUTES.CUSTOMER_FEEDBACK_FORM_SUCCESS}`}
            component={LazySuccessPage}
            exact
            key="Das hat geklappt!"
          />
          <Route
            path={`${ROUTES.EVENT_LOCATION}`}
            component={LazyEventLocationPage}
            exact
            key="Eventlocation Autokino Wien"
          />
          <Route
            path={`${ROUTES.ANREISE_AUTOKINO}`}
            component={LazyAnreiseAutokinoPage}
            exact
            key="Anreise Autokino Wien"
          />
          <Route
            path={`${ROUTES.FAQ_AUTOKINO}`}
            component={LazyFaqAutokinoPage}
            exact
            key="FAQ Autokino Wien"
          />
          <Route
            path={`${ROUTES.HAUSTIERE_AUTOKINO}`}
            component={LazyHaustierePage}
            exact
            key="FAQ Autokino Wien"
          />
          <Route
            path={`${ROUTES.JOBS_AUTOKINO}`}
            component={LazyJobsPage}
            exact
            key="Jobs Autokino Wien"
          />
          <Route
            path={`${ROUTES.PHILOSOPHIE_AUTOKINO}`}
            component={LazyPhilosophiePage}
            exact
            key="Philosophie Autokino Wien"
          />
          <Route
            path={`${ROUTES.OPENAIR_AUTOKINO}`}
            component={LazyOpenAirKinoPage}
            exact
            key="Open Air Kino Autokino Wien"
          />
          <Route
            path={`${ROUTES.SIEGERKINOTEST_AUTOKINO}`}
            component={LazySiegerKinotestPage}
            exact
            key="Sieger Kinotest 2022"
          />
          <Route
            path={`${ROUTES.PARTNER_AUTOKINO}`}
            component={LazyAutokinoPartnerPage}
            exact
            key="Partner Autokino Wien"
          />
          <Route
            path={`${ROUTES.DAS_AUTOKINO}`}
            component={LazyDasAutokinoPage}
            exact
            key="Das Autokino Wien"
          />
          <Route
            path={`${ROUTES.HOME}`}
            component={LazyHomePage}
            exact
            key="Das Autokino Wien"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_MISTELBACH}`}
            component={LazyFlohmarktMistelbachPage}
            exact
            key="Flohmarkt Mistelbach"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_JOBS_AUTOKINO}`}
            component={LazyFlohmarktJobsPage}
            exact
            key="Flohmarkt Jobs"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_NIEDEROESTERREICH}`}
            component={LazyFlohmarktNiederoesterreichPage}
            exact
            key="Flohmarkt Niederoesterreich"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_GAENSERNDORF}`}
            component={LazyFlohmarktGaenserndorfPage}
            exact
            key="Flohmarkt Gänserndorf"
          />
          <Route
            path={`${ROUTES.JOBAPPLICATION_SUCCESS}`}
            component={LazyJobApplicationSuccessPage}
            exact
            key="Bewerbung im Autokino Wien"
          />
          <Route
            path={`${ROUTES.SHOW_NOT_FOUND}`}
            component={LazyShowNotFoundPage}
            exact
            key="Vorstellung nicht gefunden"
          />
          <Route
            path={`${ROUTES.ITEM}/:id`}
            component={LazyProductPage}
            key="product"
          />
          <Route path="/giftcard" exact>
            <Redirect to="/kinogutschein" />
          </Route>
          <Route path="/programm" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/booking" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/home" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/index" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/index.html" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/index.php" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/home.php" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/home.html" exact>
            <Redirect to="/" />
          </Route>
          <Route
            path="/news-events"
            exact
            component={LandingPage}
            key="landing"
          />

          <PrivateRoute
            path={ROUTES.CART}
            component={LazyCartPage}
            key="cart"
          />
          <PrivateRoute
            path={ROUTES.WISHLIST}
            component={LazyWishlistPage}
            key="wishlist"
          />
          <Route
            path="/privacy"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/impressum-datenschutz/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/facebook"
            component={() => {
              window.location.replace('https://www.facebook.com/autokino.at/');
              return null;
            }}
          />
          <Route
            path="/facebookflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.facebook.com/FlohmarktImAutokinoWien'
              );
              return null;
            }}
          />
          <Route
            path="/facebookkinderflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.facebook.com/KinderflohmarktWienAutokino'
              );
              return null;
            }}
          />
          <Route
            path="/instagram"
            component={() => {
              window.location.replace(
                'https://www.instagram.com/autokinowien/'
              );
              return null;
            }}
          />
          <Route
            path="/instagramflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.instagram.com/flohmarkt_im_autokino_wien/'
              );
              return null;
            }}
          />
          <Route
            path="/instagramkinderflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.instagram.com/kinderflohmarkt_wien_autokino/'
              );
              return null;
            }}
          />
          <Route
            path="/sicheres-kino"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/home-2/sicheres-kino/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/agb"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/agb/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/dein-event-im-autokino"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/dein-event-im-autokino/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/deinevent"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/dein-event-im-autokino/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/aboutus"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/geschichte/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/team"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/team-partner/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/contact"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/contactprint"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/kontakt"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/geschichte"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/traktorkino"
            component={() => {
              window.location.replace('https://autokino.at/event/134');
              return null;
            }}
          />
          <Route
            path="/chanukka2021"
            component={() => {
              window.location.replace('https://autokino.at/event/233');
              return null;
            }}
          />
          <Route
            path="/mylittlepony"
            component={() => {
              window.location.replace(
                'https://www.ticket-regional.de/events_info.php?eventID=181509'
              );
              return null;
            }}
          />

          <PrivateRoute
            path={ROUTES.CHECKOUT}
            component={LazyCheckoutPage}
            key="checkout"
          />

          <Route
            path={`${ROUTES.PAYMENT}/:id`}
            component={LazyPaymentPage}
            key="payment"
          />

          <Route component={LazyNotFoundPage} key="404" />
        </Switch>
      </RouteContainer>
    </PoseGroup>
  );
};

export default withRouter(Routes);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import * as APP_CONST from '../../constants/appConsts';
import GiftCardList from '../../containers/GiftCardList/GiftCardList';
import * as actions from '../../store/actions/index';
import ResponsiveWrapper from '../../components/UI/ResponsiveWrapper/ResponsiveWrapper';
import GiftCardContentHeader from '../../components/GiftcardContentHeader/GiftCardContentHeader';
import { Helmet } from 'react-helmet';
import { GIFTCARD as landingTitle } from '../../constants/titles';
import { Carousel, Image, Col, Row } from 'react-bootstrap';
import Flohmarktstyles from '../../components/FlohmarktContentHeader/FlohmarktContentHeader.module.css';
import image1 from '../../assets/images/autokino/kinogutschein/gutschein_kino_glueckliche_besucher.jpg';
import Kinostyles from '../../components/Util/AutokinoStylesComponent.module.css';
import * as ROUTES from '../../constants/routes';

import imageHeaderDesktop from '../../assets/images/autokino/kinogutschein/foto_oben_desktop.jpg';
import imageHeaderMobile from '../../assets/images/autokino/kinogutschein/mobile/foto_oben_mobile.jpg';

import imageInfokasten from '../../assets/images/autokino/kinogutschein/mobile/Infokasten.svg';
import imageSprechblasen from '../../assets/images/autokino/kinogutschein/mobile/Sprechblasen.svg';
import imageWichtigeInfos from '../../assets/images/autokino/kinogutschein/mobile/wichtigsten_Infos_Gutscheine.svg';
import imageUhrKasten from '../../assets/images/autokino/kinogutschein/mobile/Uhr_Kasten.svg';
import imageFotosUebereinander from '../../assets/images/autokino/kinogutschein/2_Fotos_uebereinander.png';

import imageWichtigeInfosDesktop from '../../assets/images/autokino/kinogutschein/wichtigsten_infos_zum_gutschein.svg';
import imageUhrKastenDesktop from '../../assets/images/autokino/kinogutschein/Uhr.svg';
import imageInfokastenDesktop from '../../assets/images/autokino/kinogutschein/Info.svg';

const AnimatedContainer = posed.section({
  enter: {
    opacity: 1,
    staggerChildren: 200,
  },
  exit: {
    opacity: 0,
  },
});

const GiftCardPage = props => {
  const [selGiftCardsAmount, setSelGiftCardsAmount] = useState({});

  useEffect(() => {
    async function fetchGiftCards() {
      console.log('call fetch giftCards');
      await props.fetchGiftCards();
    }

    fetchGiftCards();
  }, []);

  let counterHandler = (e, id, type) => {
    const data = { ...selGiftCardsAmount };
    const oldCounter = data[id] != null ? data[id] : 1;
    let updatedCounter = oldCounter;

    console.log(
      'oldCounter Val = ' + oldCounter + ',id = ' + id + ', type = ' + type
    );

    if (type === 'inc') {
      updatedCounter += 1;
    }

    if (type === 'dec') {
      if (updatedCounter > 0) {
        updatedCounter -= 1;
      }
    }

    if (type === 'change') {
      const val = +e.target.value;
      if (val >= 0) {
        updatedCounter = Math.abs(val);
      }
      if (val > 600) {
        updatedCounter = 600;
      }
    }

    console.log('changed value = ' + updatedCounter);
    setSelGiftCardsAmount(prevState => ({
      ...prevState, // copy all other field/objects
      [id]: updatedCounter,
    }));
  };

  const renderPageHeader = () => {
    let retcontent = <div />;
    console.log('render page header called');
    retcontent = (
      <div>
        <Helmet defer={false}>
          <title>Kinogutschein Autokino &#x1F381; Jetzt Freude schenken!</title>
          <meta
            name="description"
            content="&#x1F381; Der (Auto) Kino Gutschein. Immer ein großartiges Geschenk! Freude vorprogrammiert! Einzigartiges Kinoerlebnis, Hollywood unter Sternen mit Top Service. Jetzt Kinogutschein online kaufen."
          />
          <meta
            name="keywords"
            content="Kinogutschein – schenke #ErlebnisAutokino"
          />
          <title>{landingTitle}</title>
          <link rel="canonical" href="https://autokino.at/kinogutschein" />
        </Helmet>
      </div>
    );
    return retcontent;
  };

  const renderGiftcardInfo = () => {
    let retcontent = <div />;
    console.log('render gift card info called cat = ');
    retcontent = (
      <ResponsiveWrapper>
        <Row className={Flohmarktstyles.Flex}>
          <Col xs={12} lg={12}>
            <h2 className={Flohmarktstyles.HardelaHeadline}>
              Infos zu unseren Kinogutscheinen
            </h2>
            <h3 className={Flohmarktstyles.HardelaHeadlineSmall + ' mt-2'}>
              Wie kommt der Gutschein zu Dir?
            </h3>
            <div className={Flohmarktstyles.TextContainer + ' mt-2'}>
              {' '}
              Der Kinogutschein wird Dir per E-Mail als hübsch designte
              "PDF-Datei", sofort nach deiner Bestellung, automatisch
              zugesendet. <br />
              Achte darauf, dass Du deine Mailadresse richtig angibst. Findest
              Du deinen Kino Gutschein nach 5 Minuten nicht in deinem Postfach,
              wirf doch bitte einen Blick in deinen Spam-Ordner.
            </div>
            <h3 className={Flohmarktstyles.HardelaHeadlineSmall + ' mt-2'}>
              Wie kann man den Kinogutschein einlösen?
            </h3>
            <div className={Flohmarktstyles.TextContainer + ' mt-2'}>
              Einlösbar ist der Gutschein <u>ausschließlich online</u> auf
              unserer Website www.autokino.at für Kino-Tickets und unser Welcome
              Package (Getränke + Popcorn + Goodies). <br />
              <br />
              Solltest Du nicht den ganzen Gutscheinbetrag verwendet haben,
              kannst Du bei deiner nächsten Bestellung den übrig gebliebenen
              Betrag einlösen. Gib einfach denselben Gutscheincode noch einmal
              ein. <br />
              <br />
              Sollte deine Bestellsumme höher sein, als dein Kinogutschein,
              kannst Du den Restbetrag mit allen gängigen Zahlungsmethoden
              begleichen.
              <br />
              <br />
              Die Gültigkeitsdauer ist am Gutschein vermerkt (aktuell bis Ende
              2025), diese kann aber gerne jederzeit auf Anfrage verlängert
              werden.
              <br />
              <br />
            </div>
            <h3 className={Flohmarktstyles.HardelaHeadlineSmall + ' mt-2'}>
              Kinogutschein kaufen. Freude schenken. Der Autokino Gutschein.
            </h3>
            <div className={Flohmarktstyles.TextContainer + ' mt-4'}>
              <b>#DasBesondereKinoErlebnis</b>
              <br />
              <br />
              <Carousel xs={12} lg={12} className={'p-2'}>
                <Carousel.Item>
                  <Image
                    src={image1}
                    fluid
                    alt="hund im auto kino wien"
                    title="hund kino autokino wien"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
            <br />
            <div className={Kinostyles.TextContainer + ' mt-2'}>
              <br />
              <button className={Kinostyles.CTAButton}>
                <a
                  href={ROUTES.LANDING_HEADER_SECTION}
                  className={Kinostyles.CTAButtonLink}
                >
                  Kinoprogramm
                </a>
              </button>
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </ResponsiveWrapper>
    );
    return retcontent;
  };

  const addItemToCart = (e, item) => {
    console.log('added to cart PAGE productID = ' + item.productName);
    // TODO item add to cart
    // const item = products2Cart;

    const data = { ...selGiftCardsAmount };
    const amount =
      data[item.purchaseProductId] != null ? data[item.purchaseProductId] : 1;

    console.log('add to cart amount = ' + amount);

    const itemProduct = {
      key: item.purchaseProductId,
      product: item,
      order: item.purchaseProductId,
      amount: amount,
      id: item.purchaseProductId,
      totalPrice: amount * item.price,
      show: {},
      movie: {},
      productType: APP_CONST.PRODUCT_TYPE_VOUCHER,
    };

    let productArray = [];
    productArray.push(itemProduct);
    props.onAddToCart(productArray);
  };

  console.log('render giftCards = ' + props.giftCards.length);
  return (
    <div>
      {renderPageHeader()}
      <div className="d-none d-lg-block ">
        <Image
          src={imageHeaderDesktop}
          fluid
          className={Flohmarktstyles.CenterContent}
        />
        <ResponsiveWrapper>
          <Row className={Kinostyles.Flex}>
            <Col lg={1} />
            <Col lg={5}>
              <div className={Flohmarktstyles.TextContainer + ' p-4'}>
                <br />
                <br />
                <br />
                Mit einem Autokino-Gutschein verschenkst du mehr als nur
                Kinotickets: Du schenkst die Atmosphäre einzigartiger
                Sonnenuntergänge und einen unvergesslichen Abend unter den
                Sternen!
              </div>
              <div className={Flohmarktstyles.TextContainer + ' pr-4 pl-4'}>
                <b>Weil gemeinsame Zeit wertvoll ist.</b>
              </div>
            </Col>
            <Col lg={6}>
              <Image
                src={imageFotosUebereinander}
                fluid
                className={Kinostyles.ImageBiggerPadding}
              />
            </Col>
          </Row>
          <br />
        </ResponsiveWrapper>
        <div className={Kinostyles.GrayContainer}>
          <ResponsiveWrapper>
            <GiftCardList
              amounts={selGiftCardsAmount}
              giftCards={props.giftCards}
              onAddToCart={(e, item) => addItemToCart(e, item)}
              incCounterClicked={(e, id) => counterHandler(e, id, 'inc')}
              decCounterClicked={(e, id) => counterHandler(e, id, 'dec')}
              onCounterChange={(e, id) => counterHandler(e, id, 'change')}
            />
          </ResponsiveWrapper>
        </div>
        <ResponsiveWrapper>
          <br />
          <Row>
            <Col lg={2} />
            <Col lg={8}>
              <Image
                src={imageInfokastenDesktop}
                fluid
                className={Kinostyles.ImageBiggerPaddingDesktop}
              />
              <Image
                src={imageUhrKastenDesktop}
                fluid
                className={Kinostyles.ImageBiggerPaddingDesktop}
              />
            </Col>
            <Col lg={2} />
          </Row>
          <br />
          <br />
          <div className={' p-4'}>
            <Image
              src={imageWichtigeInfosDesktop}
              fluid
              className={Kinostyles.ImageBiggerPaddingRight + ' pl-4 pt-4'}
            />
            <br />
            <br />
            <div className={Kinostyles.TextHeadlineBigNoMargin + ' pl-4 pt-4'}>
              Wie erhalte ich den Gutschein?
            </div>
            <div className={Flohmarktstyles.TextContainer + ' p-4'}>
              Der Gutschein wird dir, nach Kaufabschluss, <b>per E-Mail</b>{' '}
              zugeschickt. Du findest ihn im Anhang als schön designte
              PDF-Datei.
            </div>
            <div
              className={Kinostyles.TextHeadlineBigNoMargin + ' pl-4 pr-4 pt-2'}
            >
              Wie & für was kann man den Kinogutschein einlöst werden?
            </div>
            <div className={Flohmarktstyles.TextContainer + ' p-4'}>
              Der Gutschein kann ausschließlich in unserem online Ticketshop für
              Kinotickets & Welcome Deals (Popcorn, Nachts, Kids) eingelöst
              werden.
              <br />
              <br /> Wähle einfach deine gewünschte Anzahl an Tickets & Welcome
              Deals aus, lege sie in den Warenkorb und gib zum Schluss deinen
              Gutscheincode ein!
              <br />
              <br /> Hast du noch Restguthaben auf deinem Gutschein offen,
              kannst du das ganz einfach beim nächsten Kauf einlösen.
            </div>
            <div
              className={Kinostyles.TextHeadlineBigNoMargin + ' pl-4 pr-4 pt-2'}
            >
              Wie lange ist mein Gutschein gültig?
            </div>
            <div className={Flohmarktstyles.TextContainer + ' p-4'}>
              Dein Gutschein ist ab Kaufdatum 7 Jahre lang gültig.
            </div>
            <br />
            <div className={Kinostyles.CenterContent}>
              <button className={Kinostyles.CTAButton}>
                <a href={ROUTES.LANDING} className={Kinostyles.CTAButtonLink}>
                  Zum Kinoprogramm
                </a>
              </button>
            </div>
            <br />
            <br />
          </div>
        </ResponsiveWrapper>
      </div>
      <div className="d-lg-none">
        <Image src={imageHeaderMobile} fluid />
        <Image src={imageSprechblasen} fluid className="p-4" />
        <div className={Flohmarktstyles.TextContainer + ' p-4'}>
          Mit einem Autokino-Gutschein verschenkst du mehr als nur Kinotickets:
          Du schenkst die Atmosphäre einzigartiger Sonnenuntergänge und einen
          unvergesslichen Abend unter den Sternen!
        </div>
        <div className={Flohmarktstyles.TextContainer + ' pr-4 pl-4'}>
          <b>Weil gemeinsame Zeit wertvoll ist.</b>
        </div>
        <Image
          src={imageFotosUebereinander}
          fluid
          className={Kinostyles.ImageBiggerPadding}
        />
        <div className={Kinostyles.GrayContainer}>
          <GiftCardList
            amounts={selGiftCardsAmount}
            giftCards={props.giftCards}
            onAddToCart={(e, item) => addItemToCart(e, item)}
            incCounterClicked={(e, id) => counterHandler(e, id, 'inc')}
            decCounterClicked={(e, id) => counterHandler(e, id, 'dec')}
            onCounterChange={(e, id) => counterHandler(e, id, 'change')}
          />
        </div>
        <br />
        <Image src={imageInfokasten} fluid className="p-4" />
        <Image src={imageUhrKasten} fluid className="pr-4 pl-4 pt-2" />
        <Image
          src={imageWichtigeInfos}
          fluid
          className={Kinostyles.ImageBiggerPaddingRight + ' pl-4 pt-4'}
        />
        <br />
        <br />
        <div className={Kinostyles.TextHeadlineBigNoMargin + ' pl-4 pr-4 pt-2'}>
          Wie erhalte ich den Gutschein?
        </div>
        <div className={Flohmarktstyles.TextContainer + ' p-4'}>
          Der Gutschein wird dir, nach Kaufabschluss, <b>per E-Mail</b>{' '}
          zugeschickt. Du findest ihn im Anhang als schön designte PDF-Datei.
        </div>
        <div className={Kinostyles.TextHeadlineBigNoMargin + ' pl-4 pr-4 pt-2'}>
          Wie & für was kann man den
          <br />
          Kinogutschein einlöst werden?
        </div>
        <div className={Flohmarktstyles.TextContainer + ' p-4'}>
          Der Gutschein kann ausschließlich in unserem online Ticketshop für
          Kinotickets & Welcome Deals (Popcorn, Nachts, Kids) eingelöst werden.
          <br />
          <br /> Wähle einfach deine gewünschte Anzahl an Tickets & Welcome
          Deals aus, lege sie in den Warenkorb und gib zum Schluss deinen
          Gutscheincode ein!
          <br />
          <br /> Hast du noch Restguthaben auf deinem Gutschein offen, kannst du
          das ganz einfach beim nächsten Kauf einlösen.
        </div>
        <div className={Kinostyles.TextHeadlineBigNoMargin + ' pl-4 pr-4 pt-2'}>
          Wie lange ist mein Gutschein gültig?
        </div>
        <div className={Flohmarktstyles.TextContainer + ' p-4'}>
          Dein Gutschein ist ab Kaufdatum 7 Jahre lang gültig.
        </div>
        <Row>
          <Col xs={2} />
          <Col xs={8}>
            <button className={Kinostyles.CTAButton}>
              <a href={ROUTES.LANDING} className={Kinostyles.CTAButtonLink}>
                Zum Kinoprogramm
              </a>
            </button>
          </Col>
          <Col xs={2} />
        </Row>
        <br />
        <br />
      </div>
    </div>
  );
};

GiftCardPage.propTypes = {
  giftCards: PropTypes.arrayOf(PropTypes.object),
  fetchGiftCards: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    giftCards: state.giftCard.giftCards,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGiftCards: () => dispatch(actions.fetchGiftCard()),
    onAddToCart: item => dispatch(actions.addToCart(item)),
  };
};

// nFetchCategories
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardPage);

import * as actionTypes from './actionTypes';
import { addError } from './errors';
import API from '../../services/WsApi';
import { addNotification } from './notifications';
import * as ROUTES from '../../constants/routes';

export const saveOrderStart = () => {
  return {
    type: actionTypes.SAVE_ORDER_START,
  };
};

export const saveOrderFail = error => {
  return {
    type: actionTypes.SAVE_ORDER_FAIL,
  };
};

export const saveOrderSuccess = (orderId, orderLink) => {
  return {
    type: actionTypes.SAVE_ORDER_SUCCESS,
    orderId,
    orderLink,
  };
};

export const saveOrder = (order, totalPrice) => async (dispatch, getState) => {
  console.log('saveorder called');
  try {
    dispatch(saveOrderStart());

    console.log('order = ' + JSON.stringify(order));

    API.post('order/saveOrder ', order)
      .then(function(response) {
        var resultData = response.data;
        console.log('resultData.success = ' + resultData.success);

        if (resultData != null && resultData.success) {
          //cookie.remove('cart');
          if (
            resultData.payment_checkoutUrl !== '' &&
            !resultData.already_payed
          ) {
            window.location.href = resultData.payment_checkoutUrl;
          } else if (resultData.already_payed) {
            window.location.href = ROUTES.PAYMENT + '/' + resultData.orderId;
          }
        } else {
          dispatch(saveOrderFail());
          dispatch(
            addNotification(
              'Bestellung wurde nicht erstellt 🛒!' + resultData.errorMessage
            )
          );
        }
      })
      .catch(function(error) {
        console.log('fetchShowsFail');
        dispatch(saveOrderFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchShowsEnd');
      });

    let orderId = 10;
    let orderLink = 'autokino.at';

    dispatch(saveOrderSuccess(orderId, orderLink));
  } catch (error) {
    dispatch(saveOrderFail());
    dispatch(addError());
  }
};

export const saveCustomerFeedbackFormStart = () => {
  return {
    type: actionTypes.SAVE_CUSTOMER_FEEDBACK_FORM_START,
  };
};

export const saveCustomerFeedbackFormFail = error => {
  return {
    type: actionTypes.SAVE_CUSTOMER_FEEDBACK_FORM_FAIL,
  };
};

export const saveCustomerFeedbackFormSuccess = (orderId, orderLink) => {
  return {
    type: actionTypes.SAVE_CUSTOMER_FEEDBACK_FORM_SUCCESS,
  };
};

export const saveCustomerFeedbackForm = cfForm => async (
  dispatch,
  getState
) => {
  console.log('saveCustomerFeedbackForm called');
  try {
    dispatch(saveCustomerFeedbackFormStart());

    console.log('cfForm = ' + JSON.stringify(cfForm));

    API.post('feedback/saveCustomerFeedbackForm ', cfForm)
      .then(function(response) {
        var resultData = response.data;
        console.log('resultData.success = ' + resultData);

        if (resultData != null && resultData) {
          window.location.href = ROUTES.CUSTOMER_FEEDBACK_FORM_SUCCESS;
        } else {
          dispatch(saveCustomerFeedbackFormFail());
          dispatch(
            addNotification(
              'Beim Speichern ist ein Fehler aufgetreten' +
                resultData.errorMessage
            )
          );
        }
      })
      .catch(function(error) {
        console.log('saveCustomerFeedbackForm failed');
        dispatch(saveCustomerFeedbackFormFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('saveCustomerFeedbackForm end');
      });

    dispatch(saveCustomerFeedbackFormSuccess());
  } catch (error) {
    dispatch(saveCustomerFeedbackFormFail());
    dispatch(addError());
  }
};

export const saveJobApplicationFormStart = () => {
  return {
    type: actionTypes.SAVE_JOB_APPLIACTION_FORM_START,
  };
};

export const saveJobApplicationFormFail = error => {
  return {
    type: actionTypes.SAVE_JOB_APPLIACTION_FORM_FAIL,
  };
};

export const saveJobApplicationFormSuccess = (orderId, orderLink) => {
  window.location.href = ROUTES.JOBAPPLICATION_SUCCESS;
  return {
    type: actionTypes.SAVE_JOB_APPLIACTION_FORM_SUCCESS,
  };
};

export const saveJobApplicationForm = cfForm => async (dispatch, getState) => {
  console.log('saveJobApplicationForm called');
  try {
    dispatch(saveCustomerFeedbackFormStart());

    console.log('jAForm = ' + JSON.stringify(cfForm));

    API.post('feedback/saveJobApplicationForm ', cfForm)
      .then(function(response) {
        saveJobApplicationFormSuccess();
        dispatch(
          addNotification(
            'Deine Anfrage wurde gespeichert, wir melden uns in Kürze. Du wirst gleich auf unsere Startseite weitergeleitet'
          )
        );
      })
      .catch(function(error) {
        console.log('saveJobApplicationForm failed');
        dispatch(saveJobApplicationFormFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('saveJobApplicationForm end');
      });

    dispatch(saveJobApplicationFormSuccess());
  } catch (error) {
    dispatch(saveJobApplicationFormFail());
    dispatch(addError());
  }
};

import PropTypes from 'prop-types';

import styles from './EventThumb.module.css';
import Ribbon from './Ribbon/Ribbon';
import ActionsBox from './ActionsBox/ActionsBox';
import { EVENT_ITEM } from '../../constants/routes';
import PreloadImage from '../UI/PreloadImage/PreloadImage';
import PreloadLinkShow from '../UI/PreloadLink/Show/Show';
import Kinostyles from '../Util/AutokinoStylesComponent.module.css';
import {
  EVENT_FLOHMARKT_DETAIL,
  PUBLIC_EVENT_DETAIL,
  SHOW,
} from '../../constants/routes';

const EventThumb = props => {
  const {
    item: {
      date,
      location,
      timeSlot,
      event,
      showId,
      time,
      ribbonType,
      soldOut,
      urlIdentifier,
      showName,
    },
  } = props;
  let eventcattitle = 'Standplatz buchen';
  if (event != null && event.eventType == 'automarkt') {
    eventcattitle = 'Verkaufsplatz buchen';
  } else if (event != null && event.eventType === 'publicevent') {
    eventcattitle = 'Ticket kaufen';
  }
  const {
    inWishlist,
    onAddToCart,
    addingToCart,
    inCart,
    toggleWishlist,
    togglingWishlist,
    dateString,
  } = props;
  const getDetailPath = () => {
    return urlIdentifier
      ? event != null && event.eventType === 'publicevent'
        ? PUBLIC_EVENT_DETAIL + '/' + urlIdentifier
        : EVENT_FLOHMARKT_DETAIL + '/' + urlIdentifier
      : EVENT_ITEM + '/' + showId;
  };
  return (
    <div>
      <div className={Kinostyles.ListHeadlineCenter + ' mb-2 mt-2 d-lg-none'}>
        {dateString}
      </div>
      <div>
        <div className={styles.ImageBox}>
          <Ribbon type={ribbonType} />
          {!(showId === 174 || showId === 175 || showId === 176 || soldOut) ? (
            <PreloadLinkShow
              to={getDetailPath()}
              id={showId}
              title={
                event != null
                  ? showName
                  : location + ' | ' + date + ' | ' + time
              }
            >
              <PreloadImage
                src={event != null ? event.thumbImage : ''}
                //src={imagePath}//{imagePath}
                alt={showName}
                className={styles.Image}
                loadingClass={styles.LoadingImage}
              />
            </PreloadLinkShow>
          ) : (
            <PreloadImage
              src={event != null ? event.thumbImage : ''}
              //src={imagePath}//{imagePath}
              alt={showName}
              className={styles.Image}
              loadingClass={styles.LoadingImage}
            />
          )}
        </div>
        <div className={styles.Info}>
          <div className={styles.EventInfo}>
            <div className={styles.Title}>{showName}</div>
          </div>
          <div className={styles.EventDetail}>
            <p className={styles.EventDetailText}>Beginn: {time}</p>
          </div>
          <div
            className={styles.EventDesc + ' mb-2'}
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
          {showId !== 13 ? (
            showId === 174 || showId === 175 ? (
              <button
                onClick={null}
                className="btn btn-first btn-disabled"
                disabled={true}
              >
                GESCHLOSSEN
              </button>
            ) : !soldOut ? (
              <ActionsBox
                additionalClassName={styles.ActionsBox}
                addedToCart={onAddToCart}
                addingToCart={addingToCart}
                inCart={inCart}
                showId={showId}
                linkTarget={getDetailPath()}
                toggledWishlist={toggleWishlist}
                togglingWishlist={togglingWishlist}
                inWishlist={inWishlist}
                linkTitle={eventcattitle}
              />
            ) : (
              <button className="btn btn-first btn-disabled">
                Vorverkauf gestoppt - Tickets an der Tageskassa ab 5:30 Uhr
                erhältlich
              </button>
            )
          ) : (
            <button
              onClick={null}
              className="btn btn-first btn-disabled"
              disabled={true}
            >
              Standplatzverkauf nur Vorort
            </button>
          )}
        </div>
      </div>
      <hr className={styles.TextSeperator} />
    </div>
  );
};

EventThumb.propTypes = {
  dateString: PropTypes.string,
  inWishlist: PropTypes.bool,
  onAddToCart: PropTypes.func,
  addingToCart: PropTypes.bool,
  inCart: PropTypes.bool,
  toggleWishlist: PropTypes.func,
  togglingWishlist: PropTypes.bool,
  item: PropTypes.object,
};

export default EventThumb;
